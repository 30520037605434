.recovered-password {
  display: flex;
  margin: 0% !important;
  padding: 0% !important;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    var(--color-primary),
    var(--color-primary-dark) 25%,
    var(--color-secondary) 75%,
    var(--color-secondary-dark)
  );
}

.recovered-password .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 10px;
  align-items: center;
  min-width: 300px;
  width: auto;
  padding: 2.5%;
  border-radius: 4px;
  box-shadow: 0px 4px 16px #00000050;
}

.recovered-password .container form {
  min-width: 360px;
}

.recovered-password .container .btn {
  background: var(--color-primary) !important;
}

.recovered-password .container .btn.btn-danger {
  background: #ff000025 !important;
  color: #ffffff !important;
}

@media screen and (max-width: 375px) {
  .recovered-password .container {
    padding: 5%;
  }

  .recovered-password .container form {
    min-width: 320px;
  }
}

@media screen and (max-width: 320px) {
  .recovered-password .container {
    padding: 5%;
    box-shadow: none;
  }

  .recovered-password .container form {
    min-width: 280px;
  }
}
