footer {
    display: block;
    padding: 10px;
    width: 100% !important;
    background: var(--color-primary);
}

@media screen and (max-width: 425px) {
    footer{
        display: none !important;
    }
}


