.datetime-range .title {
    display: block;
    padding-left: 2.5%;
    text-wrap: wrap !important;
}

.table-container {
    display: block;
    max-height: 720px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.table-container::-webkit-scrollbar {
    display: none;
}

.table-container .pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end !important;
    align-content: center !important;
    background: #00000025;
    color: #00000075;

}

.table-container .pagination p {
    display: block;
    margin-top: 15px;
    font-size: 1em;
    font-weight: bolder;
}

.react-datepicker-wrapper{
    display: block;
}


@media screen and (max-width: 425px) {
    .datetime-range .title {
        display: block;
        margin: auto;
        text-align: center;
    }
}
