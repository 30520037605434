
.discounts .title {
    padding-right: 15px;
    text-wrap: nowrap;
    color: var(--color-primary);
}

.discounts .title.italic {
    font-style: italic;
    font-weight: 400;
}

.discounts .btn {
    background: #71639e;
    color: #ffffff;
}

.discounts .container {
    display: block;
    padding: 15px;
}

.discounts .card {
    display: block;
    margin: 5px;
    box-shadow: 0px 4px 4px #00000050;
}

.discounts .card .card-body {
    display: block;
    color: #6a6460 !important;
}

.discounts .card .card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100%);
    color: #ffffff !important;
}

.discounts .card.card-main {
    border: 1px solid #5eb95e;
}

.discounts .card.card-main .card-body {
    color: #5eb95e !important;
}

.discounts .card.card-main .card-img {
    background: #5eb95e;
}

.discounts .card.card-openaccount .card-img {
    background: #423dac;
}

.discounts .card.card-checks .card-img {
    background: #5277be;
}

.discounts .card.card-spends .card-img,
.discounts .card.card-buys .card-img {
    background: #4a007c;
}

.discounts .card.card-cancel .card-img {
    background: #b83fff;
}

.discounts .card.card-discounts .card-img {
    background: #ff66c2;
}

.discounts .card-text {
    font-size: 1.2rem;
    text-wrap: nowrap;
}

.discounts .nav-link {
    color: #71639e;
    font-weight: 500;
}

.discounts .nav-link:disabled {
  opacity: 0.8;
}


.discounts .nav-link.active {
    background: #71639e;
    color: #ffffff;
    border-bottom: 0px;
    box-shadow: 0px 0px 16px #00000075;
    transition: 1.5s;
    animation-duration: 2s;
}




@media screen and (max-width: 1024px) {
    .discounts .card-text,
    .discounts .card-title {
        font-size: 1rem;
        text-wrap: nowrap;
    }
}
