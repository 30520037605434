
.boxcut .title {
    padding-right: 15px;
    text-wrap: nowrap;
    color: var(--color-primary);
}

.boxcut .title.italic {
    font-style: italic;
    font-weight: 400;
}

.boxcut .btn {
    background: #71639e;
    color: #ffffff;
}

.boxcut .container {
    display: block;
    padding: 15px;
}

.boxcut .card {
    display: block;
    margin: 5px;
    box-shadow: 0px 4px 4px #00000050;
}

.boxcut .card .card-body {
    display: block;
    color: #6a6460 !important;
}

.boxcut .card .card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100%);
    color: #ffffff !important;
}

.boxcut .card.card-main {
    border: 1px solid #5eb95e;
}

.boxcut .card.card-main .card-body {
    color: #5eb95e !important;
}

.boxcut .card.card-main .card-img {
    background: #5eb95e;
}

.boxcut .card.card-openaccount .card-img {
    background: #423dac;
}

.boxcut .card.card-checks .card-img {
    background: #5277be;
}

.boxcut .card.card-spends .card-img,
.boxcut .card.card-buys .card-img {
    background: #4a007c;
}

.boxcut .card.card-cancel .card-img {
    background: #b83fff;
}

.boxcut .card.card-discounts .card-img {
    background: #ff66c2;
}

.boxcut .card-text {
    font-size: 1.2rem;
    text-wrap: nowrap;
}

.boxcut .nav-link {
    color: #71639e;
    font-weight: 500;
}

.boxcut .nav-link:disabled {
  opacity: 0.8;
}

.boxcut .nav-link.active {
    background: #71639e;
    color: #ffffff;
    border-bottom: 0px;
    box-shadow: 0px 0px 16px #00000075;
    transition: 1.5s;
    animation-duration: 2s;
}


.details-container a.disabled {
    color: #00000075 !important;
}

@media screen and (max-width: 1024px) {
    .boxcut .card-text,
    .boxcut .card-title {
        font-size: 1rem;
        text-wrap: nowrap;
    }
    .boxcut h1.fs-4,
    .boxcut span.fs-4 {
        font-size: 20px !important;
    }

    .boxcut h1.fs-2 {
        font-size: 24px !important;
    }
}


@media screen and (max-width: 425px) {

    .boxcut a ,
    .boxcut i ,
    .boxcut span {
        text-wrap: wrap !important;
        font-size: 12.5px !important;
    }
}
