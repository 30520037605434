body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-primary: #413db0;
  --color-primary-dark: #3d3aa9;
  --color-secondary: #4a007c;
  --color-secondary-dark: #450077;
}

@font-face {
  font-family: "acumin variable black italic";
  src: url("./app/assets/fonts/Acumin\ Variable\ Concept.ttf")
    format("TrueType");
  font-style: italic !important;
  font-weight: 800;
}

@font-face {
  font-family: "acumin variable italic";
  src: url("./app/assets/fonts/Acumin\ Variable\ Concept.ttf")
    format("TrueType");
  font-weight: 300;
  font-style: initial;
}
