nav {
    background: var(--color-primary);
}

nav .nav-link {
    color: #ffffff50 !important;
}

nav .nav-link.active {
    color: #ffffff !important;
}

nav .nav-mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    nav .nav-desktop {
        display: none !important;
    }
    nav .nav-mobile {
        display: block !important;
    }
}