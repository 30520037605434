
.sales .title {
    padding-right: 15px;
    text-wrap: nowrap;
    color: var(--color-primary);
}

.sales .title.italic {
    font-style: italic;
    font-weight: 400;
}

.sales .btn {
    background: #71639e;
    color: #ffffff;
}

.sales .container {
    display: block;
    padding: 15px;
}

.sales .card {
    display: block;
    margin: 5px;
    box-shadow: 0px 4px 4px #00000050;
}

.sales .card .card-body {
    display: block;
    color: #6a6460 !important;
}

.sales .card .card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100%);
    color: #ffffff !important;
}

.sales .card.card-main {
    border: 1px solid #5eb95e;
}

.sales .card.card-main .card-body {
    color: #5eb95e !important;
}

.sales .card.card-main .card-img {
    background: #5eb95e;
}

.sales .card.card-openaccount .card-img {
    background: #423dac;
}

.sales .card.card-checks .card-img {
    background: #5277be;
}

.sales .card.card-spends .card-img,
.sales .card.card-buys .card-img {
    background: #4a007c;
}

.sales .card.card-cancel .card-img {
    background: #b83fff;
}

.sales .card.card-discounts .card-img {
    background: #ff66c2;
}

.sales .card-text {
    font-size: 1.2rem;
    text-wrap: nowrap;
}

.sales .nav-item .nav-link {
    color: #71639e;
    font-weight: 500;
    margin-right: 5px;
    border: 2px solid #71639e75;
    box-shadow: 0px 2px 8px #71639e75;
}

.sales .nav-item .nav-link.active {
    background: #71639e;
    color: #ffffff;
    border: 2px solid #ffffff75;
    box-shadow: 0px 2px 8px #00000075;
}

.sales .nav-pills .nav-link{
    min-width: 128px;
    margin-bottom: 10px;
}




@media screen and (max-width: 1024px) {
    .sales .card-text,
    .sales .card-title {
        font-size: 1rem;
        text-wrap: nowrap;
    }
}


@media screen and (max-width: 425px) {
    .sales .nav-pills {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }
    .sales .nav-pills .nav-link{
        min-width: 128px;
        margin-bottom: 10px;
    }
}
